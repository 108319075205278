<script>
import FlagIcon from "@/components/FlagIcon.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "LanguageSwitcher",
  components: { FlagIcon },
  props: ["languages"],
});
</script>

<template>
  <div class="my-2 pr-10 w-full text-end">
    <FlagIcon
      v-for="language in languages"
      :language="language"
      :key="language"
      class="m-2 text-3xl rounded-full"
    />
  </div>
</template>
