import { createI18n } from "vue-i18n";

import en from "./en.json";
import sk from "./sk.json";
import ro from "./ro.json";
import hu from "./hu.json";
import cz from "./cz.json";
import hr from "./hr.json";
import pl from "./pl.json";
const i18n = createI18n({
  locale: "sk",
  messages: {
    en: en,
    ro: ro,
    hu: hu,
    sk: sk,
    cz: cz,
    hr: hr,
    pl: pl,
  },
});

export default i18n;
