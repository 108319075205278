<script>
import { defineComponent } from "vue";
import RatingComment from "./RatingComment.vue";
export default defineComponent({
  name: "StarIcon",

  props: ["included", "selected", "status", "index", "ratingSent"],

  components: { RatingComment },

  data() {
    return {
      isOpened: false,
    };
  },

  methods: {
    handleClick(index, comment) {
      this.$emit("select-rating", index, comment);
    },
    toggleOpened() {
      this.isOpened = !this.isOpened;
    },
    open() {
      if (this.ratingSent) return;
      this.isOpened = true;
    },
    close() {
      this.isOpened = false;
    },
  },
});
</script>

<template>
  <div
    class="mx-2 text-2xl"
    :class="{
      'text-primary-100 opacity-100': selected,
      'text-secondary-200': !selected,
      'opacity-40': !included && !selected,
      'opacity-100 hover:cursor-pointer': included,
    }"
    @mouseover="$emit('mouseover')"
    @mouseleave="$emit('mouseleave')"
    @click="open"
  >
    <font-awesome-icon :icon="['fas', 'star']" />
    <Teleport to="body">
      <RatingComment
        v-if="isOpened"
        @close="close"
        :index="index"
        @select-rating="handleClick"
      />
    </Teleport>
  </div>
</template>
