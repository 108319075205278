<script>
import { defineComponent } from "vue";
import RatingStars from "@/components/RatingStars.vue";

export default defineComponent({
  name: "OutputArea",
  components: { RatingStars },
  props: ["answer", "urls", "urls_names"],
  methods: {
    sendRating(index, comment) {
      this.selectedIndex = index;
      this.$emit("send-rating", index, comment);
    },
  },
});
</script>

<template>
  <div
    class="mb-0 mx-10 mb-10 bg-secondary-100 text-white flex-1 max-w-screen-lg md:min-w-sm h-[610px]"
    style="overflow: auto"
  >
    <div v-if="answer !== null">
      <RatingStars @send-rating="sendRating" />
      <p class="whitespace-pre-line px-5">
        {{ answer }}
        <span v-if="urls && urls.length > 0">
          <span v-for="(url, index) in urls" :key="index">
            <a
              class="font-semibold hover:opacity-40 text-primary-100 underline"
              :href="url"
              target="_blank"
              >{{ `${urls_names[index]}` }}</a
            >
            <span v-if="index < urls.length - 1">, </span>
          </span>
        </span>
      </p>
    </div>
  </div>
</template>
