import { createApp } from "vue";
import App from "./App.vue";
import "./style.css";
import router from "./router";
import i18n from "./locales/i18n";
import FlagIcon from "vue-flag-icon";

document.title = "AskPosybe";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import { faStar, faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

/* add icons to the library */
library.add(faStar, faCircleExclamation);

import mitt from "mitt";

const emitter = mitt();

const app = createApp(App);

app.config.globalProperties.emitter = emitter;

window.eventBus = mitt();

app
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(router)
  .use(i18n)
  .use(FlagIcon)
  .mount("#app");
