<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    language: {
      type: String,
    },
  },
  computed: {
    languageForIcon() {
      return this.language === "en" ? "gb" : this.language;
    },
  },

  methods: {
    setLocale() {
      this.$i18n.locale = this.language;
      this.$router.push({
        params: { lang: this.language },
      });
    },
  },
});
</script>

<template>
  <flag
    @click="setLocale"
    class="m-2 text-3xl rounded-full cursor-pointer"
    :class="{
      'opacity-100': language === this.$i18n.locale,
      'opacity-50': language !== this.$i18n.locale,
    }"
    :iso="languageForIcon"
  />
</template>
