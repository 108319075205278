<script>
import InputArea from "@/components/InputArea.vue";
import OutputArea from "@/components/OutputArea.vue";
import ChatBot from "@/api/chatBot";
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import { LottieLoading, LottieError } from "@/components/lottie-animations";
import WarningInfo from "@/components/WarningInfo.vue";
import StatusMessage from "@/components/StatusMessage.vue";

export default {
  name: "App",
  components: {
    WarningInfo,
    LottieError,
    LottieLoading,
    LanguageSwitcher,
    OutputArea,
    InputArea,
    StatusMessage,
  },

  data() {
    return {
      answer: null,
      urls: null,
      urls_names: null,
      answer_id: null,
      status: null,
      isLoading: false,
      isError: false,
      isStatusShown: false,
      warningInfo: null,
      languages: ["sk"],
    };
  },
  async mounted() {
    try {
      let langResponse = await ChatBot.getLang();
      this.$i18n.allLanguages = langResponse.data.languages;
      this.languages = langResponse.data.languages;

      //TODO: ziskava informaciu alebo chybu z BE, bud ako samostatne volanie alebo v ramci volani sa to ziska-> bude sa implementovat neskor
      // let infoResponse = await ChatBot.getInfo();
      // this.warningInfo = infoResponse.data.info;
    } catch (e) {
      this.isError = true;
    }
  },
  methods: {
    async ask(question) {
      this.answer = null;
      this.answer_id = null;
      this.urls = [];
      this.urls_names = [];
      this.status = null;
      this.emitter.emit("null-rating");

      if (question) {
        this.isLoading = true;
        try {
          let response = await ChatBot.ask(question, this.$i18n.locale);
          this.answer = response.data.answer;
          this.answer_id = response.data.uuid;
          this.urls = response.data.urls || [];
          this.urls_names = response.data.urls_names || [];
          this.status = response.data.status || null;
          if (this.status) {
            this.isStatusShown = true;
          }
        } catch (error) {
          this.isError = true;
          setTimeout(() => {
            this.isError = false;
            this.isLoading = false;
          }, 2000);
          return;
        }
        this.isLoading = false;
      }
    },
    async sendRating(rating, comment) {
      await ChatBot.rate(this.answer_id, rating, comment);
    },
  },
};
</script>

<template>
  <div class="flex flex-col min-h-screen bg-secondary-200">
    <header class="flex h-36 sm:h-48 items-center bg-white justify-between">
      <img
        class="sm:m-16 sm:p-0 p-5 w-2/3 sm:w-[350px] sm:h-[82.5873px]"
        src="@/assets/posybe-logo.png"
        alt="web pattern"
      />
      <img
        class="h-full object-cover object-left overflow-hidden"
        src="@/assets/square_Pattern_web.svg"
        alt="web pattern"
      />
    </header>
    <LanguageSwitcher :languages="languages" />
    <main class="flex-1 flex flex-col justify-between">
      <div class="flex justify-evenly max-lg:flex-wrap w-full">
        <div class="mx-10 lg:w-96 w-full">
          <InputArea
            @ask-question="ask"
            :is-loading="isLoading"
            :is-error="isError"
          />
          <WarningInfo :warning-info="this.warningInfo" />
        </div>

        <OutputArea
          @send-rating="sendRating"
          :answer="answer"
          :urls="urls"
          :urls_names="urls_names"
        />
      </div>
      <footer class="m-4">
        <img src="@/assets/rounded-logo.png" class="h-16" alt="logo" />
      </footer>
    </main>
  </div>
  <LottieLoading v-if="isLoading && !isError" />
  <LottieError v-if="isError" />
  <StatusMessage
    v-if="isStatusShown"
    :status="status"
    @status-close="isStatusShown = false"
  />
</template>
