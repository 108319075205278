<script>
import { defineComponent } from "vue";
import StarIcon from "@/components/StarIcon.vue";

export default defineComponent({
  name: "RatingStars",
  components: { StarIcon },

  data() {
    return {
      hoveredIndex: 0,
      selectedIndex: 0,
    };
  },
  mounted() {
    this.emitter.on("null-rating", () => {
      this.hoveredIndex = 0;
      this.selectedIndex = 0;
    });
  },

  methods: {
    sendRating(index, comment) {
      this.selectedIndex = index;
      this.$emit("send-rating", index, comment);
    },
  },
});
</script>

<template>
  <div class="flex justify-end py-4 pr-5">
    <StarIcon
      v-for="index in 5"
      :key="index"
      :included="hoveredIndex >= index && selectedIndex === 0"
      :selected="selectedIndex >= index"
      :index="index"
      :rating-sent="!!selectedIndex"
      @mouseover="hoveredIndex = index"
      @mouseleave="hoveredIndex = 0"
      @select-rating="sendRating"
    />
  </div>
</template>
