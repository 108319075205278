<script>
export default {
  name: "WarningInfo",
  props: ["warningInfo"],
};
</script>

<template>
  <div v-if="warningInfo !== null" class="flex text-white m-3">
    <font-awesome-icon
      class="text-2xl mr-5"
      icon="fa-solid fa-circle-exclamation"
    />
    <p>{{ warningInfo }}</p>
  </div>
</template>
