<script>
import { LottieAnimation } from "lottie-web-vue";
import Loading from "@/assets/lotties/loading.json";
import { defineComponent } from "vue";

export default defineComponent({
  components: { LottieAnimation },
  data() {
    return {
      Loading,
    };
  },
});
</script>

<template>
  <div class="fixed inset-0 bg-cover bg-center bg-blur backdrop-blur-sm z-10">
    <div class="h-full flex items-center justify-center">
      <LottieAnimation
        :animation-data="Loading"
        :auto-play="true"
        :loop="true"
        :speed="0.8"
        class="h-60 w-60"
      />
    </div>
  </div>
</template>
