import api from "@/api/index";
import settings from "@/assets/configFile.json";

export default {
  async ask(question, lang) {
    return await api.post(settings.api.ask, { question, lang });
  },
  async rate(uuid, rating, comment) {
    return await api.post(settings.api.rate, { uuid, rating, comment });
  },
  async getLang() {
    return await api.get(settings.api.lang);
  },
  async getInfo() {
    return await api.get(settings.api.info);
  },
};
