<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "StatusMessage",

  props: ["status"],

  methods: {
    handleClick() {
      this.$emit("statusClose");
    },
  },
});
</script>

<template>
  <div class="fixed inset-0 bg-cover bg-center bg-blur backdrop-blur-sm z-10">
    <div class="h-full flex items-center justify-center">
      <div
        class="w-1/2 bg-secondary-100 rounded-lg border-4 border-primary-100"
      >
        <div class="flex justify-end">
          <button @click="handleClick" class="mt-2 mr-2 text-black">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <p class="text-primary-100 font-bold text-2xl text-center">
          {{ $t("Warning") }}
        </p>
        <div class="pb-2 text-center text-white text-lg">{{ status }}</div>
      </div>
    </div>
  </div>
</template>
