import { createRouter, createWebHistory } from "vue-router";
import i18n from "@/locales/i18n";
import App from "@/App.vue";

const routes = [
  {
    path: "/",
    redirect: `/${i18n.global.locale}`,
  },
  {
    path: "/:lang",
    component: App,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  let language = to.params.lang;
  const allowedLanguages = i18n.global.availableLocales;
  if (!language || !allowedLanguages.includes(language)) {
    language = i18n.global.locale;
    next({
      params: { lang: language },
    });
    return;
  }
  i18n.global.locale = language;
  next();
});

export default router;
