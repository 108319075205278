<script>
import { LottieAnimation } from "lottie-web-vue";
import Error from "@/assets/lotties/error.json";
import { defineComponent } from "vue";

export default defineComponent({
  components: { LottieAnimation },
  data() {
    return {
      Error,
    };
  },
});
</script>

<template>
  <div class="fixed inset-0 bg-cover bg-center bg-blur backdrop-blur-sm z-10">
    <div class="h-full flex items-center justify-center flex-col">
      <LottieAnimation
        :animation-data="Error"
        :auto-play="true"
        :loop="false"
        :speed="0.8"
        class="h-32 w-32"
      />
      <p class="text-red-500 font-bold text-xl py-3">
        {{ $t("An error has occurred") }}
      </p>
    </div>
  </div>
</template>
