<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "RatingComment",

  props: {
    isLoading: Boolean,
    isError: Boolean,
    status: String,
    index: Number,
  },

  data() {
    return {
      comment: "",
    };
  },

  computed: {
    buttonText() {
      if (this.isLoading && this.isError) {
        return this.$t("Error...");
      } else if (this.isLoading && !this.isError) {
        return this.$t("Wait...");
      } else {
        return this.$t("feedback");
      }
    },
  },
  methods: {
    handleClick() {
      this.$emit("close");
      this.$emit("select-rating", this.index, this.comment);
    },

    closeClick(e) {
      this.$emit("close");
      this.$emit("select-rating", this.index, this.comment);
      e.stopPropagation();
    },

    sendComment() {
      this.$emit("close");
      this.$emit("select-rating", this.index, this.comment);
    },

    stopPropagation(e) {
      e.stopPropagation();
    },
  },
});
</script>

<template>
  <div class="fixed inset-0 bg-cover bg-center bg-blur backdrop-blur-sm z-10">
    <div class="h-full flex items-center justify-center">
      <div
        class="w-2/3 bg-secondary-100 rounded-lg border-4 border-primary-100"
      >
        <div class="flex justify-end">
          <button @click="handleClick" class="mt-2 mr-2 text-black">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <textarea
          v-model="comment"
          class="bg-secondary-100 w-full p-4 h-56 mt-2 text-white text-lg placeholder-opacity-60 placeholder-white justify-center"
          :placeholder="$t('Write your feedback...')"
          @keydown="handleKeyDown"
        >
        </textarea>
        <div class="flex justify-end">
          <div
            @click="sendComment()"
            class="text-white grid place-items-center w-52 h-12 m-4 font-bold text-m"
            v-bind:class="{
              'cursor-pointer bg-primary-100 hover:bg-primary-200': !isLoading,
            }"
          >
            {{ buttonText }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
