<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "InputArea",
  data() {
    return {
      question: "",
    };
  },
  props: {
    isLoading: Boolean,
    isError: Boolean,
  },
  computed: {
    buttonText() {
      if (this.isLoading && this.isError) {
        return this.$t("Error...");
      } else if (this.isLoading && !this.isError) {
        return this.$t("Wait...");
      } else {
        return this.$t("ask");
      }
    },
  },
  methods: {
    handleKeyDown(event) {
      if (event.key === "Enter") {
        if (event.shiftKey) {
          // Shift+Enter: Add a new line (default behavior)
        } else {
          // Enter: Submit the question
          event.preventDefault();
          this.$emit("ask-question", this.question);
        }
      }
    },
  },
});
</script>

<template>
  <div>
    <textarea
      v-model="question"
      class="bg-secondary-100 w-full p-4 h-56 text-white text-lg placeholder-opacity-60 placeholder-white"
      :placeholder="$t('You can ask here...')"
      @keydown="handleKeyDown"
    >
    </textarea>

    <div class="flex justify-end">
      <div
        @click="$emit('ask-question', question)"
        class="text-white grid place-items-center w-52 h-12 mt-4 mb-6 font-bold text-lg"
        v-bind:class="{
          'cursor-pointer bg-primary-100 hover:bg-primary-200': !isLoading,
          'bg-red-500': isLoading && isError,
          'bg-primary-300': isLoading && !isError,
        }"
      >
        {{ buttonText }}
      </div>
    </div>
  </div>
</template>
